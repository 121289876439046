import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Section from '../css/Section'
import Content from '../css/Content'
import PostedOn from '../css/PostedOn'
import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'
import SEO from '../components/SEO'

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html, excerpt } = markdownRemark
  const { title, date } = frontmatter
  Template.pageTitle = title

  return (
    <>
      <SEO title={title} description={excerpt} />
      <FunkyTitle title={title} />
      <Section>
        <Content>
          <div
            className="md_content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <PostedOn>Posted on: {date.toString()}</PostedOn>
        </Content>
      </Section>
    </>
  )
}

Template.propTypes = {
  data: PropTypes.object
}

Template.Layout = Layout

export default Template

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
